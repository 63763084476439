import Repository from './repository'

const resource = '/v1/treinamentos/normas'

export default {
  buscaNormaPorId (idDeficiencia) {
    return Repository.get(`${resource}/${idDeficiencia}`)
  },
  listarNormas () {
    return Repository.get(`${resource}`)
  },
  salvarNorma (deficiencia) {
    return Repository.post(`${resource}`, deficiencia)
  },
  atualizarNorma (idDeficiencia, deficiencia) {
    return Repository.put(`${resource}/${idDeficiencia}`, deficiencia)
  },
  deletarNorma (id) {
    return Repository.delete(`${resource}/${id}`)
  },
  buscarProcessoPorId (id) {
    return Repository.get(`${resource}/processos/${id}`)
  },
  listarProcessosPorNormaId (id) {
    return Repository.get(`${resource}/${id}/processos`)
  },
  salvarProcesso (deficiencia) {
    return Repository.post(`${resource}/processos`, deficiencia)
  },
  atualizarProcesso (idDeficiencia, deficiencia) {
    return Repository.put(`${resource}/processos/${idDeficiencia}`, deficiencia)
  },
  deletarProcesso (id) {
    return Repository.delete(`${resource}/processos/${id}`)
  }
}
