<template>
  <div class="container-fluid">
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col">
						<h3>Cadastro de norma</h3>
					</div>
				</div>
			</div>
			<form @submit.prevent="salvar">
				<div class="card-body">
					<div class="row">
						<div class="col-md-4 col-sm-12">
							<div class="form-group" :class="{ 'form-group--error': $v.norma.nome.$error }">
								<label for="nome" class="required">Nome</label>
								<input v-model="norma.nome" type="text" class="form-control" name="nome" id="nome">
								<div class="error-form text-danger" v-if="!$v.norma.nome.required">Campo obrigatório</div>
							</div>
						</div>
					</div>
					<hr>
					<div class="row">
						<div class="col-md-12 mt-5">
							<button type="button" class="btn btn-danger float-right mx-3" @click="goBack">CANCELAR</button>
							<button type="submit" class="btn btn-primary float-right">SALVAR</button>
						</div>
					</div>
				</div>
			</form>
		</div>
  </div>
</template>

<script>
import normasRepository from './../../../services/api/normasRepository'
import { required } from 'vuelidate/lib/validators'
export default {
	validations: {
    norma: {
			nome: {
				required
			}
		}
  },
	data () {
		return {
			norma: {
        nome: ''
			},
		}
	},
	created () {
		if (this.$route.query.id) {
			this.buscaNorma(this.$route.query.id)
		}
	},
	methods: {
		buscaNorma (id) {
			normasRepository.buscaNormaPorId(id).then(response => {
				this.norma = response.data['data']
			})
		},
		goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push({ name: 'TreinamentoNormas' })
		},
		salvar () {
			this.$v.$touch()
			if (this.$v.$invalid) {
        this.$swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Preencha o formulário corretamente!',
				})
      } else {
				if (this.norma.id) {
					normasRepository.atualizarNorma(this.norma.id, this.norma).then(response => {
						if (response.data['success']) {
							this.$swal({
								icon: 'success',
								text: 'Cadastro atualizado com sucesso!'
							}).then( () => {
								this.goBack()
							})
						}
					}).catch(error => {
						console.log(error)
						this.$swal({
							icon: 'error',
							title: 'Erro no cadastro!',
							text: error.message
						})
					})
				} else {
					this.$store.commit("setSplashScreen", true)
					normasRepository.salvarNorma(this.norma).then(response => {
						this.$store.commit("setSplashScreen", false)
						if (response.data['success']) {
							this.$swal({
								icon: 'success',
								text: 'Cadastrado realizado com sucesso!'
							}).then( () => {
								this.goBack()
							})
						} else {
							this.$swal({
								icon: 'error',
								text: 'Não foi possível realizar o cadastro!'
							}).then( () => {
								this.goBack()
							})
						}
					}).catch(error => {
						this.$store.commit("setSplashScreen", false)
						this.$swal({
							icon: 'error',
							title: 'Erro no cadastro!',
							text: error.message
						})
					})
				}
			}
		}
	}
}
</script>

<style>

</style>